<template>
  <div class="text-block-key-value">
    <Text>{{keyText}}</Text>&nbsp;<Text class="orange">{{valueText}}</Text>
  </div>
</template>



<script>
import Text from '@/components/base/text/Text.vue'

export default {
  props: {
    keyText: {
      type: String
    },
    valueText: {
      type: String
    }
  },
  components: {
    Text
  }
}
</script>



<style scoped>
.text-block-key-value {
  margin-top: 3px;
}
</style>