export const navigationData = {
    home: {
        name: "Home",
        link: "/"
    },
    services: {
        name: "Services",
        link: "/services"
    },
    portfolio: {
        name: "Portfolio",
        link: "/portfolio"
    },
    whyus: {
        name: "Why Us",
        link: "/whyus"
    },
    jobs: {
        name: "Jobs",
        link: "/jobs"
    },
    vacancy: {
        name: "Find Out More",
        link: "/vacancy"
    },
    contact: {
        name: "Contact",
        link: "/contact"
    },
    privacy: {
        name: "Privacy",
        link: "/privacy"
    }
}


export const contactFieldsData = {
    name: {
        id : "name",
        label: "Name",
        error: "Enter text (min 2 - max 30 characters)",
        regexp: new RegExp(/^[a-zA-Z ]{2,30}$/)
    },
    email: {
        id : "email",
        label: "Email",
        error: "Email address is not valid (example: xxxxxx@xxxx.xxx)",
        regexp: new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    },
    phone: {
        id : "phone",
        label: "Phone",
        error: "Enter phone number (only: +, numbers, () and space)",
        regexp: new RegExp(/^[+\d]+(?:[\d-.\s()]*)$/)
    },
    message: {
        id : "message",
        label: "Message",
        error: "Enter message (max 1000 characters)"
    }
}