<template>
  <HomeHero/>
  <HomeServices/>
  <HomeAbout/>
  <HomeWhyUs/>
</template>



<script>
import HomeHero from '@/components/layouts/main/home/HomeHero.vue'
import HomeServices from '@/components/layouts/main/home/HomeServices.vue'
import HomeAbout from '@/components/layouts/main/home/HomeAbout.vue'
import HomeWhyUs from '@/components/layouts/main/home/HomeWhyUs.vue'

export default {
  components: {
    HomeHero,
    HomeServices,
    HomeAbout,
    HomeWhyUs
  }
}
</script>