<template>

  <div class="button">
    <div>
      <slot></slot>
      <div class="arrow">
        <img src="@/assets/images/svg/arrow.svg" />
      </div>
    </div>
  </div>

</template>





<style lang="scss" scoped>
.button {
  width: fit-content;
  min-width: 130px;
  position: relative;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 400;
  color: $color-white;
  letter-spacing: 2px;
  padding: 14px 40px;
  background: linear-gradient(to right, #e15d1b 50%, #fe803f);
  border: 1px solid #da8458;
  border-radius: 12px;
  box-shadow: 0px 10px 20px #421600;
  cursor: pointer;
  transition: all 1 ease-out;
}

.button>div {
  display: flex;
  justify-content: center;
  z-index: 1;
}

.arrow {
  margin-left: 10px;
}
</style>