<template>
  <div class="anchore"></div>
</template>



<style lang="scss" scoped>
.anchore {
  position: absolute;
  float: left;
  top: 0px;
}
</style>