<template>
  <VacancyBlock>
    <Heading class="small">Skills</Heading>
    <TextList :items="items"/>
  </VacancyBlock>
</template>



<script>
import VacancyBlock from './VacancyBlock.vue'
import Heading from '@/components/base/text/Heading.vue'
import TextList from '@/components/lists/TextList.vue'

export default {
  props: {
    items: {
      type: Array
    }
  },
  components: {
    VacancyBlock,
    Heading,
    TextList
  }
}
</script>