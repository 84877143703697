<template>
  <div class="grid">
    <slot></slot>
  </div>
</template>



<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.two-columns {
  grid-template-columns: repeat(2, 1fr);
}


@media screen and (max-width: $width-container) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .one-column {
    grid-template-columns: repeat(1, 1fr);
  }
}


@media screen and (max-width: $width-mobile) {
  .grid {
    grid-template-columns: initial;
  }
}
</style>