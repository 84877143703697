<template>

  <ul>
    <li v-for="item in items" :key="item">
      <Text>{{ item }}</Text>
      <slot></slot>
    </li>
  </ul>

</template>







<script>
import Text from '@/components/base/text/Text.vue'


export default {
  props: {
    items: {
      type: Array
    }
  },

  components: {
    Text
  }
}
</script>





<style lang="scss" scoped>
ul {
  padding-left: 30px;
  margin-top: 18px;
}

li::before {
  position: absolute;
  content: "\2022";
  color: $color-orange;
  margin-top: 4px;
  margin-left: -20px;
}

.text {
  margin-top: 0;
}
</style>