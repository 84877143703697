<template>
  <div class="portfolio-list">

    <PortfolioBlock
      :imageName="`portfolio-ui.png`"
      :work="`UX/UI`"
      :date="`2021`"
      :description="`Our UX team helped a well-known London Estate Agency increase online enquires by 33% via a process of analysis and recommendations based on user behaviour and frustration points. By working in an agile and cooperative fashion we were able to quickly implement our recommendations and see results within the first two months of onboarding.`"
    >
      <IconMap/>
    </PortfolioBlock>

    <PortfolioBlock
      :imageName="`portfolio-seo.png`"
      :work="`SEO - Research`"
      :date="`2020`"
      :description="`We helped a leading sports brand recover from an algorithm penalty and increased organic traffic YoY by 23%. By undertaking link analysis, disavow and re-submission. The penalty recovery began in earnest with a content overhaul – specifically targeting keywords that we knew to drive conversions within the decision phase of the buying cycle.`"
    >
      <IconShout/>
    </PortfolioBlock>

    <PortfolioBlock
      :imageName="`portfolio-data.png`"
      :work="`Data Analysis`"
      :date="`2020`"
      :description="`Through meticulous analysis we helped our client make a 37% improvement on their YoY revenue. Operating in the highly competitive field of sporting supplements, we helped them to better understand their customers & re-evaluate their paid ads campaigns, using data and analytics to develop strategies that improved business performance and financials.`"
    >
      <IconReport/>
    </PortfolioBlock>

    <PortfolioBlock
      :imageName="`portfolio-dev.png`"
      :work="`Web Development`"
      :date="`2021`"
      :description="`From the outset our brief was to create a bespoke website for a furniture company aiming to make inroads into a highly competitive market. By working closely with stakeholders and their internal team the redesign process was a huge success, resulting in a fast – conversion focused domain which directly improved online sales by 42% over a 12-month period.`"
    >
      <IconDiagram/>
    </PortfolioBlock>

    <PortfolioBlock
      :imageName="`portfolio-ops.png`"
      :work="`Operations`"
      :date="`2019`"
      :description="`Following a complete infrastructure security audit our team fixed crucial security flaws in the systems of our clients online booking form. This closed a very real security threat and safeguarded the reputation and revenue of our client’s business.`"
    >
      <IconTimer/>
    </PortfolioBlock>

    <PortfolioBlock
      :imageName="`portfolio-design.png`"
      :work="`Design`"
      :date="`2020`"
      :description="`Our design team undertook a brand and logo refresh for one of the most well-known fashion e-commerce retailers. The design made quite the splash, gaining coverage in both digital and traditional media. Over the next year the brand continued to grow with a 20% increase in sales and 47% increase in engagement.`"
    >
      <IconMap/>
    </PortfolioBlock>

  </div>
</template>



<script>
import PortfolioBlock from './PortfolioBlock.vue'
import IconMap from '@/components/base/icons/IconMap.vue'
import IconShout from '@/components/base/icons/IconShout.vue'
import IconReport from '@/components/base/icons/IconReport.vue'
import IconDiagram from '@/components/base/icons/IconDiagram.vue'
import IconTimer from '@/components/base/icons/IconTimer.vue'
/* import IconPhone from '@/components/base/icons/IconPhone.vue' */

export default {
  components: {
    PortfolioBlock,
    IconMap,
    IconShout,
    IconReport,
    IconDiagram,
    IconTimer/* ,
    IconPhone */
  }
}
</script>



<style lang="scss" scoped>
.portfolio-list {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: repeat(2, 1fr);
}


@media screen and (max-width: $width-container) {
  .portfolio-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>