<template>
  <div class="title-block-key-value">
    <Title>{{keyTitle}} -</Title>&nbsp;<Title class="orange">{{valueTitle}}</Title>
  </div>
</template>



<script>
import Title from '@/components/base/text/Title.vue'

export default {
  props: {
    keyTitle: {
      type: String
    },
    valueTitle: {
      type: String
    }
  },
  components: {
    Title
  }
}
</script>



<style lang="scss" scoped>
.title-block-key-value {
  @extend .flex-row;
  margin-top: 3px;
}

.title {
  width: initial;
}
</style>