<template>
  <RouterLink :link="navigationData.home.link">
    <div class="logo">
      <img src="@/assets/images/header-logo.png" />
    </div>
  </RouterLink>
</template>



<script>
import { navigationData } from '@/configs/data'
import RouterLink from '@/components/RouterLink.vue'

export default {
  components: {
    RouterLink
  },

  setup() {
    return {
      navigationData
    }
  }
}
</script>




<style lang="scss" scoped>
.logo {
  width: 61px;
}

.logo img {
  width: 100%;
}



@media screen and (max-width: $width-mobile) {
  .logo {
    width: 44px;
    margin-top: 10px;
  }
}
</style>