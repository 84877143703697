<template>
  <RouterLink :link="navigationData.jobs.link">{{navigationData.jobs.name}}</RouterLink>
</template>


<script>
import NavigationLink from '@/components/base/links/NavigationLink.vue'
import RouterLink from '@/components/RouterLink.vue'

export default {
  extends: NavigationLink,
  components: {
    RouterLink
  },
  setup() {
    return{
      ...NavigationLink.setup()
    }
  }
}
</script>