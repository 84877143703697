<template>
  <Grid class="two-columns">

    <JobsBlock :data="vacancies.uxDesigner" :activeIconMap="true">
      <IconMap/>
    </JobsBlock>

    <JobsBlock :data="vacancies.seoManager">
      <IconShout/>
    </JobsBlock>

    <JobsBlock :data="vacancies.webDevelopmentManager">
      <IconReport/>
    </JobsBlock>

    <JobsBlock :data="vacancies.marketingManager">
      <IconDiagram/>
    </JobsBlock>

    <JobsBlock :data="vacancies.officeManager">
      <IconPhone/>
    </JobsBlock>

  </Grid>
</template>



<script>
import { vacancies } from '@/configs/vacancies'
import Grid from '@/components/common/grid/Grid.vue'
import JobsBlock from './JobsBlock.vue'
import IconMap from '@/components/base/icons/IconMap.vue'
import IconShout from '@/components/base/icons/IconShout.vue'
import IconReport from '@/components/base/icons/IconReport.vue'
import IconDiagram from '@/components/base/icons/IconDiagram.vue'
import IconPhone from '@/components/base/icons/IconPhone.vue'

export default {
  components: {
    Grid,
    JobsBlock,
    IconMap,
    IconShout,
    IconReport,
    IconDiagram,
    IconPhone
  },
  setup() {
    return {
      vacancies
    }
  }
}
</script>



<style lang="scss" scoped>
.jobs-list {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
}
</style>