import { createRouter, createWebHistory } from 'vue-router'
import { navigationData } from '@/configs/data'

import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Portfolio from '../views/Portfolio.vue'
import WhyUs from '../views/WhyUs.vue'
import Jobs from '../views/Jobs.vue'
import Vacancy from '../views/Vacancy.vue'
import Contact from '../views/Contact.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'


const routes = [
    {
        path: navigationData.home.link,
        name: navigationData.home.name,
        component: Home
    },
    {
        path: navigationData.services.link,
        name: navigationData.services.name,
        component: Services
    },
    {
        path: navigationData.portfolio.link,
        name: navigationData.portfolio.name,
        component: Portfolio
    },
    {
        path: navigationData.whyus.link,
        name: navigationData.whyus.name,
        component: WhyUs
    },
    {
        path: navigationData.jobs.link,
        name: navigationData.jobs.name,
        component: Jobs
    },
    {
        path: navigationData.vacancy.link,
        name: navigationData.vacancy.name,
        component: Vacancy
    },
    {
        path: navigationData.contact.link,
        name: navigationData.contact.name,
        component: Contact
    },
    {
        path: navigationData.privacy.link,
        name: navigationData.privacy.name,
        component: PrivacyPolicy
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    navigationData,
    scrollBehavior(to) {
        if (to.hash)
        {
            return {
                top: 0,
                el: to.hash,
                behavior: 'smooth'
            }
        }
        else
        {
            return {
                top: 0
            }
        }
    }
})

export default router