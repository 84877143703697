<template>
  <Section>
    <Heading>Jobs</Heading>
    <Paragraph>We are always on the lookout for talented individuals with a proven track record. Due to our continued growth and success, we are currently recruiting for variety of roles within the digital sector. At Digital Technology Group we pride ourselves on delivering a first-class service to our clients, this can only be done by employing the finest minds within each industry sector. We provide several benefits beyond the usual free fruit and coffee, to the things that really matter. Private healthcare for you and your family, company cars and the highly attractive salaries. Strictly no agencies.</Paragraph>

    <Content>
      <JobsList/>
    </Content>
  </Section>
</template>



<script>
import Section from '@/components/base/Section.vue'
import Content from '@/components/base/Content.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import JobsList from '@/components/layouts/main/jobs/JobsList.vue'

export default {
  components: {
    Section,
    Content,
    Heading,
    Paragraph,
    JobsList
  }
}
</script>