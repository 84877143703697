<template>
  <Section>
    <Heading>Portfolio</Heading>
    <Paragraph>Below are just a few of the ways we have helped our clients flourish.</Paragraph>
    <Content>
      <PortfolioList/>
    </Content>
  </Section>
</template>



<script>
import Section from '@/components/base/Section.vue'
import Content from '@/components/base/Content.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import PortfolioList from '@/components/layouts/main/portfolio/PortfolioList.vue'

export default {
  components: {
    Section,
    Content,
    Heading,
    Paragraph,
    PortfolioList
  }
}
</script>