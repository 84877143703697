<template>
  <header :class="{'full-height':fullHeight}">
    <Container class="row">
      <HeaderLogo/>
      <HeaderMenu/>
      <HeaderBurgerMenu @updatedState="onUpdatedStateBurgerMenu"/>
    </Container>
  </header>
</template>



<script>
import { ref } from 'vue'
import Container from '@/components/base/Container.vue'
import HeaderLogo from '@/components/layouts/header/HeaderLogo.vue'
import HeaderMenu from '@/components/layouts/header/HeaderMenu.vue'
import HeaderBurgerMenu from '@/components/layouts/header/HeaderBurgerMenu.vue'

export default {
  components: {
    Container,
    HeaderLogo,
    HeaderMenu,
    HeaderBurgerMenu
  },
  setup() {
    let fullHeight = ref(false);

    const onUpdatedStateBurgerMenu = (state) => { fullHeight.value = state; }

    return {
      fullHeight,
      onUpdatedStateBurgerMenu
    }
  }
}
</script>



<style lang="scss" scoped>
header {
  @extend .full-width;
  height: 0;
  position: relative;
  z-index: 3;
}

.container {
  margin-top: 30px;
  justify-content: space-between;
}


@media screen and (max-width: $width-mobile) {
  header {
    width: calc(100% - 30px);
    height: initial;
    /* position: fixed; */
    position: absolute;
    padding: 20px 15px 10px;
    margin-left: -15px;
    /* background-color: #0d1018ed; */
  }

  header.full-height {
    height: 100%;
    background-color: #0d1018;
  }

  .container {
    margin-top: 0;
  }
}
</style>