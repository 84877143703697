<template>
  <div class="input-field">
    <div class="text">
      <label :for="data.id">{{data.label}}</label>
      <div class="error" :class="{'show':isShowing}">* {{data.error}}</div>
    </div>
    <slot></slot>
  </div>
</template>



<script>
import { ref } from 'vue'

export default {
  props: {
    data: {
      type: Array
    }
  },
  setup() {
    let isShowing = ref(false);

    const setErrorStyle = (state) => {
      isShowing.value = state;
    }

    return {
      isShowing,
      setErrorStyle
    }
  }
}
</script>



<style lang="scss" scoped>
.input-field {
  @extend .flex-column;
  margin-top: 14px;
}

.text {
  @extend .flex-row;
  justify-content: space-between;
}

label {
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}

.error {
  display: none;
  font-size: 12px;
  color: #FF8C8C;
}

.show {
  display: block;
}
</style>