<template>
  <div class="menu">
    <div class="title">Links</div>
    <div class="links">
      <NavigationLinkHome/>
      <NavigationLinkJobs/>
      <NavigationLinkPortfolio/>
      <NavigationLinkServices/>
      <NavigationLinkWhyUs/>
      <NavigationLinkContact/>
      <NavigationLinkPrivacy/>
    </div>
  </div>
</template>



<script>
import NavigationLinks from '@/components/abstract/NavigationLinks.vue'

export default {
  extends: NavigationLinks
}
</script>



<style lang="scss" scoped>
.menu {
  flex-direction: column;
  text-align: right;
  padding-top: 60px;
}

.title {
  color: $color-white;
  font-size: 26px;
}

.links {
  width: 200px;
  position: relative;
  float: right;
  margin-top: 30px;
}

a {
  width: 50%;
  position: relative;
  float: left;
  font-size: 15px;
  color: $color-white;
  margin-bottom: 14px;
}

a:last-of-type {
  width: 100%;
  margin-bottom: 0;
}

a:hover {
  color: $color-orange;
}



@media screen and (max-width: $width-mobile) {
  .menu {
    text-align: center;
    margin-top: 50px;
  }

  .links {
    margin: 20px auto 0;
    float: initial;
  }
}
</style>