<template>
  <Section>
    <div class="about">
      <div class="image">
        <img src="@/assets/images/home/about-man.png"/>
      </div>
      <div class="information">
        <Title class="orange">Experience & Knowledge</Title>
        <Heading>About Digital Technology Group</Heading>
        <div class="description">
          <Paragraph>We are a full-service Digital Marketing Agency that specializes in Web Design, Development, Search Engine Optimization and UX. We provide unparalleled quality for our clients by working with the best people in the industry. Over 120 talented employees work together to create exceptional results that have made us one of the most successful agencies around today.</Paragraph>
          <TextList
            :items="[
              `Custom built websites created with your business objectives in mind`,
              `UX reports and advice designed to enhance your customer experience & increase revenue`,
              `SEO strategies bespoke to your industry and objectives, from technical support to ranking focussed roadmaps`,
              `Complete support from ideation to execution and reporting`
            ]"
          />
        </div>
      </div>
    </div>
  </Section>
</template>



<script>
import Section from '@/components/base/Section.vue'
import Heading from '@/components/base/text/Heading.vue'
import Title from '@/components/base/text/Title.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import TextList from '@/components/lists/TextList.vue'

export default {
  components: {
    Section,
    Heading,
    Title,
    Paragraph,
    TextList
  }
}
</script>




<style lang="scss" scoped>
.about {
  @extend .flex-row;
  padding: 100px 0;
  margin-left: 80px;
}

.information {
  @extend .flex-column;
  margin-left: 90px;
}

.image {
  width: 403px;
  flex-shrink: 0;
}

.image img {
  width: 100%;
}

.heading {
  margin-top: 40px;
}


@media screen and (max-width: $width-container) {
  .about {
    margin-left: 0;
  }

  .information {
    margin-left: 50px;
  }

  .image {
    width: 250px;
  }
}


@media screen and (max-width: $width-mobile) {
  .about {
    flex-direction: column;
    padding-top: 50px;
  }

  .image {
    width: 100%;
    text-align: center;
  }

  .image img {
    width: 300px;
  }

  .information {
    margin-top: 50px;
    margin-left: 0;
  }
}
</style>