<template>
  <Section class="round">
    <div class="why-us">

      <div class="information">
        <Title class="orange">Dont Just Take Our Word For It</Title>
        <Heading>Why DTG</Heading>
        <Paragraph>Our mission is to help companies in all sectors communicate better with their customers and prospects. Acting as an extension of your existing team, we help you understand your audience and buyer persona better by delivering transformational integrated campaigns.</Paragraph>
        <Paragraph>We focus heavily on strategic planning that has been specifically engineered to create the best possible outcomes for our clients to thrive in times of change - from strategy development through to creative execution, measurement, and reporting.</Paragraph>
        <Paragraph>But don’t just take our word for it, contact us today for a no obligation discussion around your business goals. We deliver free of charge advice and insights that will showcase our understanding of your needs. Every partnership begins with a conversation.</Paragraph>
      </div>

      <div class="testimonials">

        <TestimonialsBlock
          :name="`Alan`"
          :position="`OPERATIONS MANAGER`"
          :description="`“DTG showed us from our initial conversations they really understood our position within the market and where we wanted to be. I am proud of the partnership we have built and the results for our business.”`"
        />

        <TestimonialsBlock
          :name="`Margret`"
          :position="`FINANCE DIRECTOR`"
          :description="`“DTG have become an integral part of our wider team, without their expertise and knowledge we would have struggled to go above and beyond our financial goals for this year.”`"
        />

        <TestimonialsBlock
          :name="`Connor`"
          :position="`CTO`"
          :description="`“From the beginning DTG displayed a fundamental understanding that we never encountered with any agency we had partnered with before. 5 years later they are still delivering results and campaigns that are invaluable to us.”`"
        />

      </div>

    </div>
  </Section>
</template>



<script>
import Section from '@/components/base/Section.vue'
import Heading from '@/components/base/text/Heading.vue'
import Title from '@/components/base/text/Title.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import TestimonialsBlock from '@/components/layouts/main/home/why-us/TestimonialsBlock.vue'

export default {
  components: {
    Section,
    Heading,
    Title,
    Paragraph,
    TestimonialsBlock
  }
}
</script>



<style lang="scss" scoped>
.why-us {
  @extend .flex-row;
}

.information {
  @extend .flex-column;
  display: flex;
  flex-direction: column;
}

.heading {
  margin-top: 40px;
}

.testimonials {
  @extend .flex-column;
  margin-left: 90px;
}


@media screen and (max-width: $width-container) {
  .why-us {
    flex-direction: column;
    margin-left: 0;
  }

  .testimonials {
    margin-left: 0;
    margin-top: 50px;
  }
}
</style>