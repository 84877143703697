<template>
  <span>
    <slot></slot>
  </span>
</template>



<style lang="scss" scoped>
span {
  font-size: 14px;
  font-weight: 300;
  color: $color-gray;
  line-height: 24px;
}

.orange {
  color: $color-orange;
}

.little {
  font-size: 13px;
}
</style>