<template>
  <h1 class="heading">
    <slot></slot>
  </h1>
</template>



<style lang="scss">
.heading {
  width: 100%;
  font-size: 36px;
  color: $color-white;
  letter-spacing: 2px;
}

.big {
  font-size: 40px;
  letter-spacing: 3px;
  font-weight: 700;
  line-height: 53px;
}

.small {
  font-size: 26px;
}

.without-margin-top {
  margin-top: 0;
}
</style>