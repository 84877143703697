<template>
  <menu>
    <NavigationLinkHome/>
    <NavigationLinkJobs/>
    <NavigationLinkPortfolio/>
    <NavigationLinkServices/>
    <NavigationLinkWhyUs/>
    <NavigationLinkContact/>
  </menu>
</template>



<script>
import NavigationLinks from '@/components/abstract/NavigationLinks.vue'

export default {
  extends: NavigationLinks
}
</script>



<style lang="scss" scoped>
menu {
  margin-top: 30px;
}

menu>a {
  margin-left: 40px;
}

menu>a:first-of-type {
  margin-left: 0;
}


@media screen and (max-width: $width-mobile) {
  menu {
    display: none;
  }
}
</style>