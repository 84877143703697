export const vacancies = {
  uxDesigner: {
    position: "UX Designer",
    details: {
      salary: "45,000 plus benefits",
      location: "London",
      experience: "5+ years",
      contract: "Full time",
      hours: "40 hours"
    },
    shortDescription: "Are you a skilled UX/UI designer in search of a new challenge?",
    description: "Are you a skilled UX/UI designer in search of a new challenge? You will have a deep understanding of user behaviour, architecture and journeys that enable users to complete tasks such as purchases with minimal effort or obstruction. You will be highly experienced in conducting user research, learning about their goals, motivations and needs while creating intuitive journeys that satisfy user needs and our clients business objectives.",
    skills: [
      "Proven experience as a UX Designer, UI DesignerDeveloping and overseeing the implementation and execution of a marketing strategy whilst ensuring alignment with the clients wider business strategy",
      "Background in project management and research",
      "Strong portfolio of design projects",
      "Expert with interaction design and information architecture",
      "Analytical mind with a business acumen",
      "BSc in Design, Computer Science, or a related field"
    ]
  },
  seoManager: {
    position: "Seo Manager",
    details: {
      salary: "45,000 plus benefits",
      location: "London",
      experience: "5+ years",
      contract: "Full time",
      hours: "40 hours"
    },
    shortDescription: "We are on the lookout for a skilled SEO with a heavy focus on research and development of brands from infancy to multinational.",
    description: "We are on the lookout for a skilled SEO with a heavy focus on research and development of brands from infancy to multinational. Alongside years of hands on, demonstratable experience you will have a solid understand not only of the technical aspects of SEO but the business objectives, KPI’s and revenue streams which underpin the creation of an SEO strategy that is presentable to stakeholders and partners.",
    skills: [
      "Ability to conduct technical site audits and analysis across several brands to ensure they are set up in line with SEO best practice & to uncover any issue that could affect a search engines ability to crawl, index and rank",
      "Working closely with Web Development team to implement and measure changes",
      "Data analysis including monitoring traffic, rankings, and conversions alongside making recommendations to maximise traffic, visibility & revenue",
      "Keyword research analysis for content creation",
      "Manage or produce regular reports on performance and traffic deliverable to stakeholders ",
      "Stay up to date on the ever-changing landscape of SEO and algorithm alterations to ensure our clients are one step ahead of the competition"
    ]
  },
  webDevelopmentManager: {
    position: "Lead Web Developer",
    details: {
      salary: "75+ benefits",
      location: "London",
      experience: "5+ years",
      contract: "Full time",
      hours: "40 hours"
    },
    shortDescription: "We are searching for a team lead Web Developer who is experienced in managing large teams across multiple disciplines.",
    description: "We are searching for a team lead Web Developer who is experienced in managing large teams across multiple disciplines. You will have demonstrable experience in building web projects for multinational clients alongside working with colleagues to understand the requirements of each sector. You will be comfortable working in a fast-paced busy environment with an onus on deliverability and client success.",
    skills: [
      "Proficient understanding of web markup, including HTML5, CSS3",
      "Basic understanding of server-side CSS pre-processing platforms, such as LESS and SASS",
      "Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery",
      "Proficient understanding of cross-browser compatibility issues and ways to work around them",
      "Good understanding of SEO principles and ensuring that application will adhere to them",
      "Advanced knowledge of JavaScript libraries and frameworks, such as AngularJS, KnockoutJS, BackboneJS, ReactJS & DurandalJS"
    ]
  },
  marketingManager: {
    position: "Marketing Manager",
    details: {
      salary: "40,000 plus benefits",
      location: "London",
      experience: "5+ years",
      contract: "Full time",
      hours: "40 hours"
    },
    shortDescription: "Are you a Marketing Manager with a keen eye for detail?",
    description: "Are you a Marketing Manager with a keen eye for detail? You will oversee every aspect of a campaign from ideation right through to execution, measurement, and client feedback. You can work comfortably in an agile environment and will be open to feedback and change, with a thirst for data analysis and research that will pinpoint audience needs. Must be capable of managing a large team and working to deadlines.",
    skills: [
      "Developing and overseeing the implementation and execution of a marketing strategy whilst ensuring alignment with the clients wider business strategy",
      "Identifying market trends and understanding the approach competitors are undertaking to help create a strategy which gives our clients a competitive advantage",
      "Drive innovative ways to gain customer engagement across digital",
      "Drive SEM and implement SEO standards across the wider marketing unit",
      "Experience building complex marketing programs and reporting on the results",
      "Strong project management skills"
    ]
  },
  officeManager: {
    position: "Office Manager",
    details: {
      salary: "30,000 plus benefits",
      location: "London",
      experience: "5+ years",
      contract: "Full time",
      hours: "40 hours"
    },
    shortDescription: "Do you have a passion for people and good service? ",
    description: "Do you have a passion for people and good service? We are looking for an office manager who will be the face of our business for visitors and the first point of contact for anyone ringing our office. This is a busy, vibrant role and will suit those who have previous experience in managing a large workspace.",
    skills: [
      "Diary and travel management for the company employees",
      "Consultancy operations, client liaison and vehicle fleet administration",
      "Organising meetings, scheduling appointments and bookings for travel",
      "Performing data entry, including updating records and databases",
      "Maintaining office policies and managing health and safety procedures",
      "Manage inbound calls, reception duties and hosting guests"
    ]
  }
}