<template>
  <Section class="round">
    <Anchor id="anchor-services"/>
    <div class="information">
      <Heading class="without-margin-top">Services For You</Heading>
      <Paragraph>Take a look at our core services, vital to the success or failure of your business. Our experts create measurable and memorable campaigns that speak to your audience.</Paragraph>
    </div>
    <Grid class="one-column">
      <ServicesBlock
        :heading="`Marketing`"
        :description="`Speak to your audience in a way which is clear, confident and delivers a message that is unforgettable. Our team have years of experience delivering the very best marketing campaigns.`"
      >
        <IconDiagram/>
      </ServicesBlock>

      <ServicesBlock
        :heading="`UI/UX`"
        :description="`We create seamless journeys and remove hidden barriers that are holding back your users. Our integrated approach and reports will highlight actionable paths to increase your conversions and revenue.`"
      >
        <IconMap/>
      </ServicesBlock>

      <ServicesBlock
        :heading="`SEO`"
        :description="`Our ethos is to work in tandem with your business KPI’s to ensure we deliver the very best in technical & content, engaging with your target audience to drive online sales while increasing brand awareness organically.`"
      >
        <IconShout/>
      </ServicesBlock>

    </Grid>
    <div>
      <Button @click="moveToServices">More</Button>
    </div>
  </Section>
</template>



<script>
import { navigationData } from '@/configs/data'
import { useRouter  } from "vue-router";
import Anchor from '@/components/base/anchor/Anchor.vue'
import Section from '@/components/base/Section.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import Button from '@/components/links/Button.vue'
import Grid from '@/components/common/grid/Grid.vue'
import ServicesBlock from '@/components/layouts/main/services/ServicesBlock.vue'
import IconDiagram from '@/components/base/icons/IconDiagram.vue'
import IconMap from '@/components/base/icons/IconMap.vue'
import IconShout from '@/components/base/icons/IconShout.vue'

export default {
  components: {
    Anchor,
    Section,
    Heading,
    Paragraph,
    Button,
    Grid,
    ServicesBlock,
    IconDiagram,
    IconMap,
    IconShout
  },
  setup() {
    const router = useRouter();

    const moveToServices = () => {
      router.push({
        path: navigationData.services.link
      });
    }

    return {
      moveToServices
    }
  }
}
</script>



<style lang="scss" scoped>
.anchore {
  top: -90px;
}

.information {
  @extend .flex-column;
  width: 100%;
  max-width: 550px;
  text-align: center;
  margin: 0 auto;
}

.text {
  margin-top: 28px;
}

.grid {
  margin-top: 50px;
}

.button {
  margin: 40px auto 0;
}


@media screen and (max-width: $width-mobile) {
  .anchore {
    top: -85px;
  }
}
</style>