<template>
  <div class="block">
    <Heading class="small">{{heading}}</Heading>
    <slot></slot>
  </div>
</template>



<script>
import Heading from '@/components/base/text/Heading.vue'

export default {
  props: {
    heading : {
      type: String,
      default: null
    }
  },
  components : {
    Heading
  }
}
</script>



<style scoped>
.block {
  margin-top: 45px;
}
</style>