<template>
  <Section>
    <div class="hero">
      <div class="information">
        <Title class="orange">All your Digital touchpoints, covered</Title>
        <Heading class="big">Professional services from industry leaders with a clear roadmap to success</Heading>
        <Paragraph class="little">Increase your online presence with a tailor-made marketing strategy to meet your specific business objectives</Paragraph>
        <div>
          <Button @click="moveToHashServices">Get Started</Button>
        </div>
      </div>
      <div class="image">
        <img src="@/assets/images/home/hero.png" />
      </div>
    </div>
  </Section>
</template>



<script>
import { useRouter  } from "vue-router";
import Section from '@/components/base/Section.vue'
import Heading from '@/components/base/text/Heading.vue'
import Title from '@/components/base/text/Title.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import Button from '@/components/links/Button.vue'

export default {
  components: {
    Section,
    Heading,
    Title,
    Paragraph,
    Button
  },
  setup() {
    const router = useRouter();

    const moveToHashServices = () => {
      router.push({
        hash: "#anchor-services"
      });
    }

    return {
      moveToHashServices
    }
  }
}
</script>



<style lang="scss" scoped>
.hero {
  @extend .flex-row;
  justify-content: space-between;
  padding-bottom: 100px;
}

.information {
  @extend .flex-column;
  width: 510px;
  min-height: 420px;
  margin-top: 70px;
  z-index: 2;
}

.heading {
  margin-top: 24px;
}

.text {
  max-width: 470px;
}

.button {
  margin-top: 34px;
}

.image {
  width: 485px;
  height: 0;
  z-index: 1;
}


@media screen and (max-width: $width-container) {
  .image {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .image img {
    opacity: 0.05;
  }
}


@media screen and (max-width: $width-mobile) {
  .hero {
    display: block;
    position: relative;
  }

  .information {
    width: 100%;
    min-height: initial;
    position: relative;
    margin-top: 0;
  }

  .heading {
    font-size: 28px;
    line-height: 44px;
  }

  .image {
    width: 450px;
    right: -100px;
    top: -20px;
  }

  .image img {
    width: 100%;
    opacity: 0.05;
  }
}
</style>