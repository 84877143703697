<script>
import { navigationData } from '@/configs/data'

export default {
  props : {
    link : {
      type: String,
      default: ""
    }
  },
  setup() {
    return{
      navigationData
    }
  }
}
</script>



<style lang="scss">
a {
  font-size: 15px;
  color: $color-white;
}

a:first-of-type {
  margin-left: 0;
}

a:hover,
a.router-link-exact-active {
  color: $color-orange;
}
</style>