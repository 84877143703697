<template>
  <h2 class="title">
    <slot></slot>
  </h2>
</template>



<style lang="scss" scoped>
.title {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: $color-white;
  text-transform: uppercase;
}

.orange {
  color: $color-orange;
}
</style>