<template>

  <div class="container">
    <slot></slot>
  </div>

</template>






<style lang="scss" scoped>
.container {
  @extend .flex-column;
  width: 100%;
  max-width: $width-container;
  position: relative;
}

.row {
  flex-direction: row;
}
</style>