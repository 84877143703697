<template>

  <Icon :imageName="`icon-map.png`" />

</template>




<script>
import Icon from "@/components/abstract/Icon"

export default {
  components: {
    Icon
  }
}
</script>