<template>
  <footer>
    <Container>
      <div>
        <FooterRequisites/>
        <FooterMenu/>
      </div>
    </Container>
  </footer>
</template>



<script>
import Container from '@/components/base/Container.vue'
import FooterRequisites from '@/components/layouts/footer/FooterRequisites.vue'
import FooterMenu from '@/components/layouts/footer/FooterMenu.vue'

export default {
  components: {
    Container,
    FooterRequisites,
    FooterMenu
  }
}
</script>



<style lang="scss" scoped>
footer {
  @extend .full-width;
  position: relative;
  padding-top : 100px !important;
  padding-bottom: 100px !important;
  z-index: 2;
}

.container>div {
  @extend .flex-row;
  justify-content: space-between;
}

.logo img {
  width: 111px;
}


@media screen and (max-width: $width-mobile) {
  .container>div {
    flex-direction: column;
    text-align: center;
  }
}
</style>