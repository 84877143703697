<template>
  <Section>
    <div class="heading-icon">
      <div>
        <IconMap :class="{'activeIconMap':activeIconMap}"/>
        <IconShout :class="{'activeIconShout':activeIconShout}"/>
        <IconReport :class="{'activeIconReport':activeIconReport}"/>
        <IconDiagram :class="{'activeIconDiagram':activeIconDiagram}"/>
        <IconTimer :class="{'activeIconTimer':activeIconTimer}"/>
        <IconPhone :class="{'activeIconPhone':activeIconPhone}"/>
      </div>
      <div>
        <Heading>Job - {{position}}</Heading>
      </div>
    </div>
    <Content>
      <VacancyDetails :details="details"/>
      <VacancyDescription :text="description"/>
      <VacancySkills :items="skills"/>
      <VacancyMessage :position="position"/>
    </Content>
  </Section>
</template>



<script>
import { vacancyData } from '@/compositions/vacancy'
import Section from '@/components/base/Section.vue'
import Content from '@/components/base/Content.vue'
import Heading from '@/components/base/text/Heading.vue'
import IconMap from '@/components/base/icons/IconMap.vue'
import IconShout from '@/components/base/icons/IconShout.vue'
import IconReport from '@/components/base/icons/IconReport.vue'
import IconDiagram from '@/components/base/icons/IconDiagram.vue'
import IconTimer from '@/components/base/icons/IconTimer.vue'
import IconPhone from '@/components/base/icons/IconPhone.vue'
import VacancyDetails from '@/components/layouts/main/vacancy/VacancyDetails.vue'
import VacancyDescription from '@/components/layouts/main/vacancy/VacancyDescription.vue'
import VacancySkills from '@/components/layouts/main/vacancy/VacancySkills.vue'
import VacancyMessage from '@/components/layouts/main/vacancy/VacancyMessage.vue'

export default {
  props: {

  },
  components: {
    Section,
    Content,
    Heading,
    IconMap,
    IconShout,
    IconReport,
    IconDiagram,
    IconTimer,
    IconPhone,
    VacancyDetails,
    VacancyDescription,
    VacancySkills,
    VacancyMessage
  },
  setup() {
    let position;
    let details;
    let description;
    let skills;
    let activeIconMap;
    let activeIconShout;
    let activeIconReport;
    let activeIconDiagram;
    let activeIconTimer;
    let activeIconPhone;

    if(vacancyData.value != null) {
      position = vacancyData.value.position;
      details = vacancyData.value.details;
      description = vacancyData.value.description;
      skills = vacancyData.value.skills;
    }

    switch (position) {
      case "UX Designer": activeIconMap=true; break;
      case "Seo Manager": activeIconShout=true; break;
      case "Lead Web Developer": activeIconReport=true; break;
      case "Marketing Manager": activeIconDiagram=true; break;
      case "Office Manager": activeIconPhone=true; break;
    }

    return {
      vacancyData,
      position,
      details,
      description,
      skills,
      activeIconMap,
      activeIconShout,
      activeIconReport,
      activeIconDiagram,
      activeIconTimer,
      activeIconPhone
    }
  }
}
</script>



<style lang="scss" scoped>
.heading-icon {
  display: flex;
}

.heading-icon>div:first-of-type {
  margin-top: -22px;
}

.heading-icon>div:last-of-type {
  margin-left: 25px;
}

.icon {
  display: none;
}

.activeIconMap,
.activeIconShout,
.activeIconReport,
.activeIconDiagram,
.activeIconTimer,
.activeIconPhone {
  display: initial;
}


.content {
  @extend .flex-column;
}
</style>