<template>
  <Section>
    <Heading>Services For You</Heading>
    <Paragraph>We are living in a world that is changing at breakneck speed. Business success has become more unpredictable, complex, and challenging, it takes an entirely different kind of agency to deliver immediate results while also achieving long term business growth for our clients. We bring together some of, if not all best minds in Marketing, UX/UI, Web Development & Business Intelligence. This enables us to increase engagement across channels and make smarter choices, resulting in positive changes for our clients revenue streams.</Paragraph>
    <Content>
      <ServicesList/>
      <div>
        <Button @click="moveToContact">Contact Us</Button>
      </div>
    </Content>
  </Section>
</template>



<script>
import { navigationData } from '@/configs/data'
import { useRouter  } from "vue-router";
import Section from '@/components/base/Section.vue'
import Content from '@/components/base/Content.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import ServicesList from '@/components/layouts/main/services/ServicesList.vue'
import Button from '@/components/links/Button.vue'

export default {
  components: {
    Section,
    Content,
    Heading,
    Paragraph,
    ServicesList,
    Button
  },
  setup() {
    const router = useRouter();

    const moveToContact = () => {
      router.push({
        path: navigationData.contact.link
      });
    }

    return {
      moveToContact
    }
  }
}
</script>



<style scoped>
.button {
  margin: 40px auto 0;
}
</style>