<template>
  <transition name="fade">
    <div class="notification" v-if="notificationState">Your message has been sent</div>
  </transition>
</template>



<script>
import { notificationState } from '@/compositions/notification'

export default {
  setup() {
    return {
      notificationState
    }
  }
}
</script>



<style scoped>
.notification {
    display: flex;
    width: 100%;
    height: 70px;
    position: fixed;
    font-size: 18px;
    color: #fff;
    top: 0;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #e15d1b, #ff7730);
    z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}


@media screen and (max-width: 884px) {
    #notification {
        height: 60px;
    }
}
</style>