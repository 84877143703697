<template>
  <section>
    <Container>
      <slot></slot>
    </Container>
  </section>
</template>



<script>
import Container from '@/components/base/Container.vue'

export default {
  components: {
    Container
  }
}
</script>



<style lang="scss" scoped>
section {
  @extend .full-width;
}

.round {
  width: calc(100% - 20px);
  padding: 60px 10px 100px;
  background-color: #05090e;
  border-radius: 30px;
}


@media screen and (max-width: $width-container) {
  .round {
    margin-left: 0;
  }
}
</style>