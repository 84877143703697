<template>
  <MessageBlock ref="block" :data="contactFieldsData.email">
    <Input ref="input" :data="contactFieldsData.email" @onUpdatedErrorStyle="updatedErrorStyle"/>
  </MessageBlock>
</template>



<script>
import { contactFieldsData } from '@/configs/data'
import ExtensionMessageBlock from '@/components/extensions/message/ExtensionMessageBlock.vue'
import MessageBlock from '@/components/base/message/MessageBlock.vue'
import Input from '@/components/base/inputs/Input.vue'

export default {
  extends: ExtensionMessageBlock,
  components: {
    MessageBlock,
    Input
  },
  setup() {
    return {
      ...ExtensionMessageBlock.setup(),
      contactFieldsData
    }
  }
}
</script>