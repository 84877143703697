<template>
  <RoundedBlock>
    <div class="block">
      <div class="image">
        <slot></slot>
      </div>
      <div class="information">
        <Title>{{data.position}}</Title>
        <div class="details">
          <TextBlockKeyValue :keyText="`Experience:`" :valueText="data.details.experience" />
          <TextBlockKeyValue :keyText="`Contract:`" :valueText="data.details.contract" />
          <TextBlockKeyValue :keyText="`Hours:`" :valueText="data.details.hours" />
        </div>
        <Text>{{data.shortDescription}}</Text>
        <RouterLink :link="navigationData.vacancy.link" @click="setVacancyData">{{navigationData.vacancy.name}}</RouterLink>
      </div>
    </div>
  </RoundedBlock>
</template>



<script>
import { navigationData } from '@/configs/data'
import { vacancyData } from '@/compositions/vacancy'
import RoundedBlock from "@/components/base/blocks/RoundedBlock.vue"
import Title from '@/components/base/text/Title.vue'
import Text from '@/components/base/text/Text.vue'
import TextBlockKeyValue from '@/components/common/text/TextBlockKeyValue.vue'
import RouterLink from '@/components/RouterLink.vue'

export default {
  props: {
    data: {
      type: Array
    }
  },
  components: {
    RoundedBlock,
    Title,
    Text,
    TextBlockKeyValue,
    RouterLink
  },
  setup(props) {
    const setVacancyData = () => {
      vacancyData.value = { ...props.data };
    }

    return {
      navigationData,
      vacancyData,
      setVacancyData
    }
  }
}
</script>



<style lang="scss" scoped>
.block {
  @extend .flex-row;
  height: 100%;
}

.image {
  margin-right: 24px;
}

.information {
  @extend .flex-column;
}

.details {
  margin-top: 18px;
}

span {
  margin-top: 16px;
  margin-bottom: 18px;
}

a {
  text-decoration: underline;
  margin-top: auto;
}


@media screen and (max-width: $width-mobile) {
  .block {
    flex-direction: column;
  }

  .information {
    margin-top: 20px;
  }
}
</style>