<template>
  <VacancyBlock>
    <Anchor id="anchor-message"/>
    <RoundedBlock>
      <Heading class="small">Apply</Heading>
      <Grid class="two-columns">
        <div>
          <MessageBlockName ref="messageBlockName" />
          <MessageBlockEmail ref="messageBlockEmail" />
          <MessageBlockPhone ref="messageBlockPhone" />
        </div>
        <div>
          <MessageBlockMessage ref="messageBlockMessage" />
          <Button @click="checkData">Send</Button>
        </div>
      </Grid>
    </RoundedBlock>
  </VacancyBlock>
</template>



<script>
import { messageType } from '@/compositions/message'
import Anchor from '@/components/base/anchor/Anchor.vue'
import ExtensionMessage from '@/components/extensions/message/ExtensionMessage.vue'
import VacancyBlock from './VacancyBlock.vue'
import RoundedBlock from '@/components/base/blocks/RoundedBlock.vue'
import Heading from '@/components/base/text/Heading.vue'
import Grid from '@/components/common/grid/Grid.vue'
import MessageBlockName from '@/components/common/message/MessageBlockName.vue'
import MessageBlockEmail from '@/components/common/message/MessageBlockEmail.vue'
import MessageBlockPhone from '@/components/common/message/MessageBlockPhone.vue'
import MessageBlockMessage from './VacancyMessageBlockMessage.vue'
import Button from '@/components/links/Button.vue'

export default {
  extends: ExtensionMessage,
  props: {
    position: {
      type: String
    }
  },
  components: {
    Anchor,
    VacancyBlock,
    RoundedBlock,
    Heading,
    Grid,
    MessageBlockName,
    MessageBlockEmail,
    MessageBlockPhone,
    MessageBlockMessage,
    Button
  },
  setup(props) {
    messageType.value = "Vacancy: " + props.position;

    return {
      messageType,
      ...ExtensionMessage.setup()
    }
  }
}
</script>



<style lang="scss" scoped>
.anchore {
  top: -30px;
}

.grid {
  grid-gap: 30px;
}

.heading {
  margin-bottom: 30px;
}

.button {
  width: auto;
  margin-top: 14px;
}


@media screen and (max-width: $width-mobile) {
  .grid {
    grid-gap: initial;
  }

  .anchore {
    top: -25px;
  }
}
</style>