<template>
  <RoundedBlock>
    <Heading class="small">Message</Heading>
    <MessageBlockName ref="messageBlockName"/>
    <MessageBlockEmail ref="messageBlockEmail"/>
    <MessageBlockPhone ref="messageBlockPhone"/>
    <MessageBlockMessage ref="messageBlockMessage"/>
    <Button class="button" @click="checkData">Send</Button>
  </RoundedBlock>
</template>



<script>
import { messageType } from '@/compositions/message'
import ExtensionMessage from '@/components/extensions/message/ExtensionMessage.vue'
import RoundedBlock from '@/components/base/blocks/RoundedBlock.vue'
import Heading from '@/components/base/text/Heading.vue'
import MessageBlockName from '@/components/common/message/MessageBlockName.vue'
import MessageBlockEmail from '@/components/common/message/MessageBlockEmail.vue'
import MessageBlockPhone from '@/components/common/message/MessageBlockPhone.vue'
import MessageBlockMessage from './ContactMessageBlockMessage.vue'
import Button from '@/components/links/Button.vue'

export default {
  extends: ExtensionMessage,
  components: {
    RoundedBlock,
    Heading,
    MessageBlockName,
    MessageBlockEmail,
    MessageBlockPhone,
    MessageBlockMessage,
    Button
  },
  setup() {
    messageType.value = "Contact Form"

    return {
      ...ExtensionMessage.setup()
    }
  }
}
</script>



<style scoped>
.heading {
  margin-bottom: 30px;
}

.button {
  width: auto;
  margin-top: 14px;
}
</style>