<template>
  <Icon :imageName="`icon-diagram.png`" />
</template>



<script>
import Icon from "@/components/abstract/Icon"

export default {
  components: {
    Icon
  }
}
</script>