<script>
import { ref } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { messageType } from '@/compositions/message'
import { notificationState } from '@/compositions/notification'

export default {
  setup() {
    const token = ref();
    const messageBlockName = ref();
    const messageBlockEmail = ref();
    const messageBlockPhone = ref();
    const messageBlockMessage = ref();

    const checkData = (e) => {
      e.preventDefault();

      if (messageBlockName.value.getStateValidate() &&
          messageBlockEmail.value.getStateValidate() &&
          messageBlockPhone.value.getStateValidate() &&
          messageBlockMessage.value.getStateValidate())
      {
        recaptcha();
      }
      else
      {
        if (!messageBlockName.value.getStateValidate())
          messageBlockName.value.setErrorStyle();

        if (!messageBlockEmail.value.getStateValidate())
          messageBlockEmail.value.setErrorStyle();

        if (!messageBlockPhone.value.getStateValidate())
          messageBlockPhone.value.setErrorStyle();

        if (!messageBlockMessage.value.getStateValidate())
          messageBlockMessage.value.setErrorStyle();
      }
    }

    const { recaptchaLoaded, executeRecaptcha } = useReCaptcha();

    const recaptcha = async () => {
      await recaptchaLoaded();
      token.value = await executeRecaptcha('login');
      submitForm();
    }

    const FORMSPARK_ACTION_URL = "https://submit-form.com/1f4cK2YG";

    const submitForm = async () => {
      await fetch(FORMSPARK_ACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          messageType: messageType.value,
          name:    messageBlockName.value.getValueFromTextField(),
          email:   messageBlockEmail.value.getValueFromTextField(),
          phone:   messageBlockPhone.value.getValueFromTextField(),
          message: messageBlockMessage.value.getValueFromTextField(),
          "g-recaptcha-response": token.value
        })
      }).then(() => {
        messageBlockName.value.reset();
        messageBlockEmail.value.reset();
        messageBlockPhone.value.reset();
        messageBlockMessage.value.reset();
        notificationActivate();
      });
    }

    const notificationActivate = () => {
      notificationState.value = true;

      setTimeout(() => {
        notificationDeactivate();
      }, 2000);
    }

    const notificationDeactivate = () => {
      notificationState.value = false;
    }

    return {
      messageBlockName,
      messageBlockEmail,
      messageBlockPhone,
      messageBlockMessage,
      recaptcha,
      checkData,
      submitForm,
      notificationState
    }
  }
}
</script>