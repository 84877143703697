<template>
  <div class="block">
    <div>
      <img src="@/assets/images/icons/icon-testimonials.png" />
    </div>
    <div>
      <TitleBlockKeyValue :keyTitle="name" :valueTitle="position"/>
      <Text>{{description}}</Text>
    </div>
  </div>
</template>



<script>
import TitleBlockKeyValue from '@/components/common/text/TitleBlockKeyValue.vue'
import Text from '@/components/base/text/Text.vue'

export default {
  props: {
    name: {
      type: String
    },
    position: {
      type: String
    },
    description: {
      type: String
    }
  },
  components: {
    TitleBlockKeyValue,
    Text
  }
}
</script>



<style lang="scss" scoped>
.block {
  display: flex;
  width: 460px;
  margin-top: 45px;
}

.block:first-of-type {
  margin-top: 0;
}

img {
  margin-top: -14px;
}

.title-block-key-value {
  margin-bottom: 12px;
}


@media screen and (max-width: $width-container) {
  .block {
    width: 100%;
  }
}
</style>