<template>
  <input  ref="inputField"
          :id="data.id"
          class="text-field"
          :class="{
            'successful': stateSuccessfulStyle,
            'error': stateErrorStyle
          }"
          @focus="focus"
          @blur="blur"
  />
</template>



<script>
import InputField from '@/components/abstract/InputField.vue'

export default {
  extends: InputField,
  setup(props, { emit }) {
    return {
      ...InputField.setup(props, { emit })
    }
  }
}
</script>