<template>
  <p>
    <Text>
      <slot></slot>
    </Text>
  </p>
</template>



<script>
import Text from './Text.vue'

export default {
  components: {
    Text
  }
}
</script>



<style lang="scss" scoped>
p {
  width: 100%;
  margin-top: 25px;
}

.orange {
  color: $color-orange;
}

.little {
  font-size: 13px;
}
</style>