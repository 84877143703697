<template>
  <Grid>

    <ServicesBlock
      :heading="`Marketing`"
      :description="`In a connected world, it has never been more important for your organization to connect with audiences in a meaningful way. Digital media opens a host of new opportunities for your brand to grow and tap into new markets. But engaging with audiences is about much more than producing content or campaigns. You need to understand the culture and needs of each market and create a brand message that resonates with your audience.`"
    >
      <IconDiagram/>
    </ServicesBlock>

    <ServicesBlock
      :heading="`UI/UX`"
      :description="`Anyone who has ever used the web will have experienced frustrating online journeys. They can be slow, confusing, unconvincing, and ultimately lead to abandonment.
                    UX optimization is about looking at your digital channels as your audiences see them; about following the paths that they would naturally follow & making improvements that have a real impact on your conversions.`"
    >
      <IconMap/>
    </ServicesBlock>

    <ServicesBlock
      :heading="`SEO`"
      :description="`SEO success is vital for any business that is looking to grow. Through insightful data analysis we can support your business, putting it on the map for your users and potential customers. Our experts can help build authority, trust, and relevancy via a multi-disciplinary approach, bringing together technical and creatives to meet the needs of your audience and strengthen your brand.`"
    >
      <IconShout/>
    </ServicesBlock>

    <ServicesBlock
      :heading="`Customer Support`"
      :description="`An often-overlooked area, Customer Support is the first line of contact many will have with your business. First impressions count so you need to make sure your support team are committed, caring and talented. This is where we excel, our support services provide a 5-star treatment, from the first hello right through to follow up and resolution. Speak with us today to find out more.`"
    >
      <IconPhone/>
    </ServicesBlock>

    <ServicesBlock
      :heading="`Security & Protection`"
      :description="`Protection of your customers and your company data has never been more crucial. Our digital security team have years of experience helping some of the biggest brands understand and manage their data to protect themselves and customers. From initial audit through to on-going management we can create security solutions of unrivalled quality and strength.`"
    >
      <IconTimer/>
    </ServicesBlock>

    <ServicesBlock
      :heading="`Business Development`"
      :description="`Business Development is the lifeblood of your company’s future. Without and effective Business Development Strategy your business is unlikely to grow & is at real risk of being overtaken by your competitors. We craft unique roadmaps that underpin your direction with specific goals to measure your industry impact. `"
    >
      <IconReport/>
    </ServicesBlock>

  </Grid>
</template>



<script>
import Grid from '@/components/common/grid/Grid.vue'
import ServicesBlock from './ServicesBlock.vue'
import IconDiagram from '@/components/base/icons/IconDiagram.vue'
import IconMap from '@/components/base/icons/IconMap.vue'
import IconShout from '@/components/base/icons/IconShout.vue'
import IconPhone from '@/components/base/icons/IconPhone.vue'
import IconTimer from '@/components/base/icons/IconTimer.vue'
import IconReport from '@/components/base/icons/IconReport.vue'

export default {
  components: {
    Grid,
    ServicesBlock,
    IconDiagram,
    IconMap,
    IconShout,
    IconPhone,
    IconTimer,
    IconReport
  }
}
</script>