<template>
  <RadialGradients/>
  <Header/>
  <Main>
    <router-view/>
  </Main>
  <Footer/>
  <Notification/>
</template>



<script>
import RadialGradients from '@/components/layouts/backgrounds/RadialGradients.vue'
import Header from '@/components/layouts/header/Header.vue'
import Main from '@/components/base/Main.vue'
import Footer from '@/components/layouts/footer/Footer.vue'
import Notification from '@/components/layouts/notification/Notification.vue'

export default {
  components: {
    RadialGradients,
    Header,
    Main,
    Footer,
    Notification
  }
}
</script>



<style lang="scss">
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    outline: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

body {
  position: relative;
  background: #0d1018;
}

#app {
  @extend .text-default;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
}

main {
  width: 100%;
  position: relative;
  float: left;
  z-index: 1;
}
</style>