<template>
  <textarea ref="inputField"
            :id="data.id"
            class="text-field"
            :class="{
              'successful': stateSuccessfulStyle,
              'error': stateErrorStyle
            }"
            @focus="focus"
            @blur="blur"
            maxlength="1000"
  ></textarea>
</template>



<script>
import InputField from '@/components/abstract/InputField.vue'

export default {
  props: {
    heightJob: {
      type: Boolean
    },
    heightContact: {
      type: Boolean
    }
  },
  extends: InputField,
  setup(props, { emit }) {
    return {
      ...InputField.setup(props, { emit })
    }
  }
}
</script>



<style scoped>
.text-field {
  resize: none;
}

.height-job {
  height: 130px;
}

.height-contact {
  height: 130px;
}
</style>