<template>
  <VacancyBlock>
    <Heading class="small">Description</Heading>
    <Paragraph>{{text}}</Paragraph>
  </VacancyBlock>
</template>



<script>
import VacancyBlock from './VacancyBlock.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'


export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  components: {
    VacancyBlock,
    Heading,
    Paragraph
  }
}
</script>
