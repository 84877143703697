<template>

  <div class="rounded-block">
    <slot></slot>
  </div>

</template>



<style scoped>
.rounded-block {
  background: linear-gradient(to right bottom, #0e1217, #191c22);
  border: 1px solid #20262e;
  padding: 30px 20px;
  border-radius: 30px;
}
</style>