<script>
import { ref, watch } from 'vue'

export default {
  props: {
    data: {
      type: Array
    }
  },
  emits: ['onUpdatedErrorStyle'],
  setup(props, { emit }) {
    const inputField = ref();

    let stateValidate = ref(false);
    let stateSuccessfulStyle = ref(false);
    let stateErrorStyle = ref(false);

    const setStateValidate = (value) => {
      stateValidate.value = value;
    }

    const focus = () => {
      resetStyles();
    }

    const blur = (e) => {
      defineState(e.target);
    }

    const defineState = (element) => {
      let value = element.value;

      if (value != "")
      {
        if (props.data.regexp == null || (props.data.regexp != null && props.data.regexp.test(value)))
        {
          setStateValidate(true);
          setSuccessfulStyle();
        }
        else
        {
          setErrorStyle();
        }
      }
      else
      {
        reset();
      }
    }

    const reset = () => {
      inputField.value.value = "";
      setStateValidate(false);
      resetStyles();
    }

    const resetStyles = () => {
      stateSuccessfulStyle.value = false;
      stateErrorStyle.value = false;
    }

    const setSuccessfulStyle = () => {
      stateSuccessfulStyle.value = true;
      stateErrorStyle.value = false;
    }

    const setErrorStyle = () => {
      stateErrorStyle.value = true;
      stateSuccessfulStyle.value = false;
    }

    watch(stateErrorStyle, (newValue, oldValue) => {
      if(newValue != oldValue)
        emit('onUpdatedErrorStyle', stateErrorStyle.value);
    });

    const getValue = () => {
      return inputField.value.value;
    }

    return {
      inputField,
      stateValidate,
      stateSuccessfulStyle,
      stateErrorStyle,
      focus,
      blur,
      reset,
      setErrorStyle,
      getValue
    }
  }
}
</script>