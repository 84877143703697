<template>
  <div class="burger-menu">

    <div>
      <div class="icons" @click="changeState">
        <img src="@/assets/images/svg/burger-icon.svg" :class="{'active': !active}" />
        <img src="@/assets/images/svg/close-icon.svg" :class="{'active': active}" />
      </div>
    </div>

    <menu :class="{'active': active}">
      <NavigationLinkHome @click="changeState"/>
      <NavigationLinkJobs @click="changeState"/>
      <NavigationLinkPortfolio @click="changeState"/>
      <NavigationLinkServices @click="changeState"/>
      <NavigationLinkWhyUs @click="changeState"/>
      <NavigationLinkContact @click="changeState"/>
    </menu>

  </div>
</template>



<script>
import { ref, watch } from 'vue'
import NavigationLinks from '@/components/abstract/NavigationLinks.vue'

export default {
  extends: NavigationLinks,
  emits: ['updatedState'],
  setup(_, { emit }) {
    let active = ref(false);

    const changeState = () => {
      active.value = (active.value) ? false : true;
    }

    const hide = () => {
      active.value = false;
    }

    watch(active, (newValue, oldValue) => {
      if(newValue != oldValue)
        emit('updatedState', active.value);
    });

    return {
      active,
      changeState,
      hide
    }
  }
}
</script>



<style lang="scss" scoped>
.burger-menu {
  @extend .flex-column;
  display: none;
}

.burger-menu>div {
  margin-top: 24px;
}

.icons {
  cursor: pointer;
}

.icons>img {
  display: none;
  float: right;
}

.icons>img.active {
  display: block;
}


menu {
  @extend .flex-column;
  display: none;
  text-align: right;
}

menu.active {
  display: flex;
}

menu>a {
  font-size: 24px;
  margin-top: 40px;
  margin-left: 0;
}

menu>a:first-of-type {
  margin-left: 0;
}


@media screen and (max-width: $width-mobile) {
  .burger-menu {
    display: flex;
  }
}
</style>