<template>

  <div class="content">
    <slot></slot>
  </div>

</template>




<script>
export default {
  setup() {

  }
}
</script>




<style>
.content {
  margin-top: 50px;
}
</style>