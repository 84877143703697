<template>
  <Section>
    <div class="information">
      <div>
        <Heading>Contact Us</Heading>
        <Paragraph>Our team are on hand to answer any query you may have, from new partnerships to job vacancies. Drop us a message on the contact form below and one of our team will be in touch within 24 hours.</Paragraph>
        <div class="image-mobile">
          <img src="@/assets/images/contact/contact.png"/>
        </div>
        <Content>
          <ContactMessage/>
        </Content>
      </div>
      <div class="image-desktop">
        <img src="@/assets/images/contact/contact.png"/>
      </div>
    </div>
  </Section>
</template>



<script>
import Section from '@/components/base/Section.vue'
import Content from '@/components/base/Content.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import ContactMessage from '@/components/layouts/main/contact/ContactMessage.vue'

export default {
  components: {
    Section,
    Content,
    Heading,
    Paragraph,
    ContactMessage
  }
}
</script>



<style lang="scss" scoped>
.information {
  display: flex;
}

.button {
  width: initial;
  margin-top: 12px;
}

.image-desktop {
  margin-left: 60px;
}

.image-desktop img {
  margin-top: 70px;
}

.image-mobile {
  display: none;
}


@media screen and (max-width: $width-container) {
  .image-desktop {
    display: none;
  }

  .image-mobile {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
}
</style>