<template>
  <div class="radial-gradients"></div>
</template>



<style lang="scss" scoped>
.radial-gradients {
  position: absolute;
  width: 100%;
  height: 100%;
  float: left;
  top: 0;
  left: 0;
  background-image: radial-gradient(300px 300px at calc(50% - 380px) calc(0% + 270px), #0D0E30 10%, #0d101800 65%),
                    radial-gradient(300px 300px at calc(50% + 70px) calc(0% + 540px), #0D1B30 10%, #0d101800 65%),
                    radial-gradient(170px 170px at calc(50% - 350px) calc(100% - 280px), #022522 0%, #0d101800 65%),
                    radial-gradient(300px 300px at calc(50% + 90px) calc(100% - 470px), #2A1728 0%, #0d101800 65%),
                    radial-gradient(400px 400px at calc(50% + 600px) calc(100% - 350px), #0D1F3099 0%, #0d101800 65%)
}
</style>