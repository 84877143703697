<script>
import { ref } from 'vue'

export default {
  setup() {
    const block = ref();
    const input = ref();

    const getStateValidate = () => {
      return input.value.stateValidate;
    }

    const setErrorStyle = () => {
      input.value.setErrorStyle(true);
    }

    const updatedErrorStyle = (state) => {
      block.value.setErrorStyle(state)
    };

    const getValueFromTextField = () => {
      return input.value.getValue();
    }

    const reset = () => {
      input.value.reset();
    }

    return {
      block,
      input,
      getStateValidate,
      setErrorStyle,
      updatedErrorStyle,
      getValueFromTextField,
      reset
    }
  }
}
</script>