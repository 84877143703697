<template>
  <Section>
    <Heading>Privacy Policy</Heading>

    <BlockPrivacyPolicy :heading="`1. Introduction`">
      <Paragraph>We are committed to safeguarding and ensuring the privacy and security of your personal information. This Policy explains what happens to personal data that you provide to us, or which we collect during your use of our website, including how we use it and when we may disclose it.</Paragraph>
      <Paragraph>Our Privacy Policy complies with Data Protection Legislation.</Paragraph>
      <Paragraph>Data Protection Legislation means:<br/>
            (i) the Data Protection Act 2018; and<br/>
            (ii) Unless and until the General Data Protection Regulation ((EU) 2016/679) (GDPR) is no longer directly applicable in the UK, the GDPR and any national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK; and then<br/>
            (iii) Any successor legislation to the GDPR or the Data Protection Act 1998.<br/>
            This Privacy Policy applies only to this website and other web sites produced by us. We may update this Policy from time to time by publishing a version on our website.</Paragraph>
      <Paragraph>If you do not accept this Policy, you must not use this site.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`2. Personal Information We May Collect`">
      <Paragraph>We may collect and process personal information from you, such as your name, telephone numbers or email addresses, when you complete inquiry forms on our website. In addition to personal information, we may collect information about your company and its activities.</Paragraph>
      <Paragraph>We may collect, store, and use the following kinds of personal information:<br/>
            (a) information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths)<br/>
            (b) information you provide when purchasing our services<br/>
            (c) information contained in or relating to any communication that you send to us (including the communication content and metadata associated with the communication)<br/>
            (d) any other personal information that you choose to send to us</Paragraph>
      <Paragraph>Before you disclose to us the personal information of another person, you must obtain that person’s consent to both the disclosure and the processing of that personal information in accordance with this policy.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`3. The Purposes for Collecting Your Personal Information`">
      <Paragraph>We collect your personal information for the following primary purposes:</Paragraph>
      <TextList
        :items="[
          `to carry out our general business operations;`,
          `to effectively provide you with our goods and services;`,
          `to communicate with you;`,
          `to respond to your enquiries and complaints;`,
          `to meet our legal and regulation obligations;`,
          `to conduct, improve and develop our relationship with you;`,
          `to carry out direct marketing, i.e. to provide you with product and promotional information and details of offers to which you have subscribed;`,
          `to improve our websites;`,
          `to keep our website secure and prevent fraud;`
        ]"
      />
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`4. Cookies`">
      <Paragraph>A cookie consists of information sent by a web server to a web browser and stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser.</Paragraph>
      <Paragraph>We may use both "session" cookies and "persistent" cookies on the website. We will use the session cookies to: keep track of you whilst you navigate the website. We will use the persistent cookies to: enable our website to recognise you when you visit.</Paragraph>
      <Paragraph>Session cookies will be deleted from your computer when you close your browser. Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date.<br/>
      Most browsers allow you to reject all cookies, whilst some browsers allow you to reject just third party cookies. For example, in Internet Explorer you can refuse all cookies by clicking "Tools", "Internet Options", "Privacy", and selecting "Block all cookies" using the sliding selector. Blocking all cookies will, however, have a negative impact upon the usability of many websites.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`5. Use of Your Information`">
      <Paragraph>By using our site, you agree that we may collect, hold, process and use your information (including personal information) for the primary purposes set out above. In addition to this, we may also use your personal information to:</Paragraph>
      <TextList
        :items="[
          `develop the design and style of the sites to improve the services provided to you;`,
          `inform you about the latest changes to our website or changes to our products and services and/or promotional offers that you might find interesting;`,
          `provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information);`,
          `contact you with details of other products you may be interested in but only if we believe it is relevant to you or your company;`,
          `communicate, and personalise such communication, with you to:`,
        ]"
      />
      <TextList
        :items="[
          `inform you if you have been successful in any site competitions or promotions;`,
          `compile customer reviews;`,
          `conduct market research;`,
          `carry out statistical, technical and logistical analysis`
        ]"
      />
      <Paragraph>If you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the licence you grant to us.</Paragraph>
      <Paragraph>We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party’s direct marketing.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`6. Disclosures`">
      <Paragraph>We will not disclose your personal information to another party except for as set out in this Privacy Policy and in the following specific instances:</Paragraph>
      <TextList
        :items="[
          `if we sell any of our business or our business as a whole, to the relevant buyer;`,
          `if disclosure is required to comply with applicable laws and government or regulatory bodies’ lawful requests for such information;`,
          `in the interests of fraud protection or to reduce the risk of fraud; or`,
          `where applicable, to any other member of our group (i.e. our subsidiaries, our holding company or its other subsidiaries).`
        ]"
      />
      <Paragraph>We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors insofar as reasonably necessary for the purposes set out in this policy.We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors insofar as reasonably necessary for the purposes set out in this policy.</Paragraph>
      <Paragraph>Except as provided in this policy, we will not provide your personal information to third parties.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`7. International data transfers`">
      <Paragraph>Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this privacy policy. Information which you provide may be transferred to countries (including the United States, which do not have data protection laws equivalent to those in force in the European Economic Area. In addition, personal information that you submit for publication on the website will be published on the internet and may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others. You expressly agree to such transfers of personal information.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`8. Storage, Safeguards and Security`">
      <Paragraph>We may transfer your personal information outside of the European Economic Area (EEA) for processing and storing. By using our website and providing your personal information to us you agree to this transfer, storing or processing. You may refuse or withdraw such consent at any time by contacting us and if this will affect our ability to provide you with products or services, we will let you know within a reasonable period of time.</Paragraph>
      <Paragraph>Unless we obtain your consent for us to retain your personal information for a longer period of time, and subject to retention necessary for compliance with our legal obligations or in relation to a legal claim, we will not retain your personal information for longer than is necessary for the purpose it was obtained. We will therefore retain your personal information for a period of 2 years following the date of our last contact with you, at which time we will delete your personal information from our systems.</Paragraph>
      <Paragraph>Whilst we take all reasonable steps to keep your personal information safe and secure, no data transmission over the internet can be entirely secure, so we do not guarantee the security of your personal information and/or use of the Site. We use reasonable endeavours to protect the security of your personal information from unauthorised access or use by using encryption technology.<br/>
      Our website might include links to third party websites. You should review the privacy policies of external websites before sending them any personal data. We do not accept any responsibility or liability for any third-party policies.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`9. Policy Amendments`">
      <Paragraph>We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes. We may also notify you of changes to our privacy policy by email or through our website.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`10. Your rights`">
      <Paragraph>Data Protection Legislation provides you with the following rights in relation to your personal information:</Paragraph>
      <TextList
        :items="[
          `the right to access;`,
          `the right to rectification;`,
          `the right to erasure;`,
          `the right to restrict processing;`,
          `the right to object to processing;`,
          `the right to data portability;`,
          `the right to complain to a supervisory authority; and`,
          `the right to withdraw consent.`
        ]"
      />
      <Paragraph>If you wish to review or receive copies of the personal information we hold about you please contact us using the details set out in the ‘Contact Us’ section, below. We will provide the first copy of your personal information free of charge unless your request is unfounded or excessive. You are entitled to receive your personal information in a structured, commonly used, and machine-readable format. We may charge a small administration fee in relation to fulfilling additional requests for access to your personal information.</Paragraph>
      <Paragraph>If any of the personal information we hold about you is inaccurate or incomplete you may request that we correct it or you may request that any processing of your personal information is restricted (i.e. because it is inaccurate, the processing is unlawful or you have objected to the processing). If processing is restricted for these or any other reasons, we may still store your personal information.</Paragraph>
      <Paragraph>You may request that your personal information is erased where: it is no longer necessary for us to retain it for the purpose it was collected; you have withdrawn consent to the processing if your consent was required; the processing relates to direct marketing; or your personal information is being processed unlawfully. However, please note that it may be necessary to continue to retain some of your personal information so that we comply with our legal obligations and in relation to claims made against or by us.</Paragraph>
      <Paragraph>You may object to the processing of your personal information if the legal basis for our processing relates to a task performed in the public interest; in the exercise of official authority vested in us; that it is necessary for the purposes of our legitimate interests or those of a third party; or for statistical purposes. If you so object, we will stop any such processing unless we can demonstrate compelling legitimate grounds for the processing and those grounds override your interests, rights and freedoms, or the processing relates to a legal claim.</Paragraph>
      <Paragraph>You may request that we stop processing your personal data for direct marketing at any time. For example, you may unsubscribe from any of our newsletters by clicking on the “Unsubscribe” link at the bottom of each. However, we may retain your email address to ensure that our newsletter is not sent to you after you have unsubscribed. You have the right to refuse consent, or withdraw consent previously provided, to the disclosure of your personal information at any time by contacting us. If this will affect our ability to provide you with goods or services, we will notify you as soon as is practicable.</Paragraph>
    </BlockPrivacyPolicy>

    <BlockPrivacyPolicy :heading="`11. Contacting Us`">
      <Paragraph>Should you have any requests or complaints in relation to your personal information or this Privacy Policy, please contact us in any of the following ways:</Paragraph>
      <TextList
        :items="[
          `By post: Digital Technology Group Limited, 61 Bridge Street, Kington, HR5 3DJ`,
          `Through our Contact form`
        ]"
      />
      <Paragraph>We will consider your question or complaint and respond to you in a reasonable timeframe.</Paragraph>
    </BlockPrivacyPolicy>

  </Section>
</template>



<script>
import Section from '@/components/base/Section.vue'
import Heading from '@/components/base/text/Heading.vue'
import BlockPrivacyPolicy from '@/components/modules/blocks/BlockPrivacyPolicy.vue'
import TextList from '@/components/lists/TextList.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'

export default {
  components: {
    Section,
    Heading,
    BlockPrivacyPolicy,
    TextList,
    Paragraph
  }
}
</script>



<style scoped>
.block>p {
  margin-top: 40px;
}

.block>p:first-of-type {
  margin-top: 24px;
}
</style>