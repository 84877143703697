<script>
import NavigationLinkHome from '@/components/common/navigation/NavigationLinkHome.vue'
import NavigationLinkJobs from '@/components/common/navigation/NavigationLinkJobs.vue'
import NavigationLinkPortfolio from '@/components/common/navigation/NavigationLinkPortfolio.vue'
import NavigationLinkServices from '@/components/common/navigation/NavigationLinkServices.vue'
import NavigationLinkWhyUs from '@/components/common/navigation/NavigationLinkWhyUs.vue'
import NavigationLinkContact from '@/components/common/navigation/NavigationLinkContact.vue'
import NavigationLinkPrivacy from '@/components/common/navigation/NavigationLinkPrivacy.vue'

export default {
  components: {
    NavigationLinkHome,
    NavigationLinkJobs,
    NavigationLinkPortfolio,
    NavigationLinkServices,
    NavigationLinkWhyUs,
    NavigationLinkContact,
    NavigationLinkPrivacy
  }
}
</script>