<script>
import MessageBlockMessage from '@/components/common/message/MessageBlockMessage.vue'

export default {
  extends: MessageBlockMessage,
  setup() {
    return {
      ...MessageBlockMessage.setup()
    }
  }
}
</script>



<style scoped>
textarea {
  height: 130px;
}
</style>