<template>
  <div class="requisites">

    <div class="logo">
      <img src="@/assets/images/footer-logo.png" />
    </div>

    <Paragraph class="description">
      Digital Technology Group provide all the services you will need to make a success of your business. Many of our clients are long term partners, with an understanding based on trust and results. Contact us to begin our partnership today.
    </Paragraph>

    <Paragraph class="address">
      Digital Technology Group Limited, 61 Bridge Street, Kington, HR5 3DJ
    </Paragraph>

  </div>
</template>



<script>
import Paragraph from '@/components/base/text/Paragraph.vue'

export default {
  components: {
    Paragraph
  }
}
</script>



<style lang="scss" scoped>
.requisites {
  @extend .flex-column;
}

.logo img {
  width: 111px;
}

.description {
  max-width: 320px;
}


@media screen and (max-width: $width-mobile) {
  .description {
    max-width: 100%;
  }

  .address {
    margin-top: 10px;
  }
}
</style>