<template>
  <div class="icon">
    <img :src="require(`@/assets/images/icons/${imageName}`)" />
  </div>
</template>



<script>
export default {
  props: {
    imageName: {
      type: String
    }
  }
}
</script>