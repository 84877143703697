<template>
  <VacancyBlock>
    <Heading class="small">Details</Heading>
    <div class="details">
      <div>
        <TextBlockKeyValue :keyText="`Experience:`" :valueText="details.experience" />
        <TextBlockKeyValue :keyText="`Contract:`" :valueText="details.contract" />
        <TextBlockKeyValue :keyText="`Hours:`" :valueText="details.hours" />
      </div>
      <div>
        <Button @click="moveToHashMessage">Apply</Button>
      </div>
    </div>
  </VacancyBlock>
</template>



<script>
import { useRouter  } from "vue-router";
import VacancyBlock from './VacancyBlock.vue'
import Heading from '@/components/base/text/Heading.vue'
import TextBlockKeyValue from '@/components/common/text/TextBlockKeyValue.vue'
import Button from '@/components/links/Button.vue'

export default {
  props: {
    details: {
      type: Array
    }
  },
  components: {
    VacancyBlock,
    Heading,
    TextBlockKeyValue,
    Button
  },
  setup() {
    const router = useRouter();

    const moveToHashMessage = () => {
      router.push({
        hash: "#anchor-message"
      });
    }

    return {
      moveToHashMessage
    }
  }
}
</script>



<style lang="scss" scoped>
.details {
  @extend .flex-row;
  justify-content: space-between;
  margin-top: 18px;
}

.details>div:first-of-type {
  @extend .flex-column;
}

.details>div:last-of-type {
  display: flex;
}

.button {
  align-self: flex-end;
}


@media screen and (max-width: $width-mobile) {
  .details {
    flex-direction: column;
  }

  .button {
    margin-top: 20px;
  }
}
</style>