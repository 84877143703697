<template>
  <div>
    <slot></slot>
  </div>
</template>



<style lang="scss" scoped>
div {
  @extend .flex-column;
  position: relative;
  margin-bottom: 50px;
}
</style>