import { ref, watch } from 'vue'

let init = localStorage.getItem("vacancyData");

export let vacancyData = ref(init ? JSON.parse(init) : undefined);

watch(
  () => vacancyData.value,
  to => {
      localStorage.setItem("vacancyData", JSON.stringify(to));
  }
)