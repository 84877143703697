<template>
  <Section>
    <div class="why-us">
      <div>
        <Heading>Why DTG</Heading>
        <Paragraph>At Design Technology Group, we’ve always worked across a variety of industries. We use our knowledge to help clients succeed with the right skillsets for any market they're in - whether that's B2B or B2C; nationwide or international. Working closely alongside your team we will give you access and insight into areas that will improve your overall business operations. Everything we do is managed in-house from project management, insights, right through to QA testing, so you can be assured of transparency and clear communication from the outset.</Paragraph>
        <Paragraph>We love to see the direct impact our work has on business owners’ lives; it provides a real inspiration for any partnership we undertake. Unlike other agencies we do not employ account managers as a single point of contact. We keep the lines of communication open between all team members for every client, so you can get answers and feedback at any time of the day. Communication, transparency, and results is what separates the good agencies from the truly great ones.</Paragraph>
        <Paragraph>We are a dedicated team of marketeers, analysts, and creative minds, all with decades of experience in our fields. Whether you need to create a brand from the ground up, or reinvigorate an existing one, we can help. From creating compelling and stylish content for your website to engaging advertising campaigns on social media. Don't just take our word for it, get in touch today to find out more.</Paragraph>
        <div>
          <Button @click="moveToContact">Contact Us</Button>
        </div>
      </div>
      <div class="image">
        <img src="@/assets/images/why/why-man.png"/>
      </div>
    </div>
  </Section>
</template>



<script>
import { navigationData } from '@/configs/data'
import { useRouter  } from "vue-router";
import Section from '@/components/base/Section.vue'
import Heading from '@/components/base/text/Heading.vue'
import Paragraph from '@/components/base/text/Paragraph.vue'
import Button from '@/components/links/Button.vue'

export default {
  components: {
    Section,
    Heading,
    Paragraph,
    Button
  },
  setup() {
    const router = useRouter();

    const moveToContact = () => {
      router.push({
        path: navigationData.contact.link
      });
    }

    return {
      moveToContact
    }
  }
}
</script>



<style lang="scss" scoped>
.why-us {
  @extend .flex-row;
}

.button {
  margin-top: 40px;
}

.image {
  width: 403px;
  margin-left: 60px;
  flex-shrink: 0;
}

.image img {
  width: 100%;
  margin-top: 70px;
}


@media screen and (max-width: $width-container) {
  .why-us {
    flex-direction: column;
  }

  .image {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .image img {
    width: 400px;
  }
}


@media screen and (max-width: $width-mobile) {
  .image {
    width: 100%;
    text-align: center;
  }

  .image img {
    width: 300px;
  }
}
</style>