<template>
  <RoundedBlock>
    <slot></slot>
    <Heading class="small">{{heading}}</Heading>
    <Paragraph>{{description}}</Paragraph>
  </RoundedBlock>
</template>



<script>
import RoundedBlock from "@/components/base/blocks/RoundedBlock.vue"
import Heading from "@/components/base/text/Heading.vue"
import Paragraph from "@/components/base/text/Paragraph.vue"

export default {
  props: {
    heading: {
      type: String
    },
    description: {
      type: String
    }
  },
  components: {
    RoundedBlock,
    Heading,
    Paragraph
  }
}
</script>



<style lang="scss" scoped>
.rounded-block {
  @extend .flex-column;
  text-align: center;
  padding-bottom: 50px;
}

.rounded-block>div,
.heading {
  position: relative;
  float: left;
}

.heading {
  margin-top: 26px;
}
</style>