<template>
  <MessageBlock ref="block" :data="contactFieldsData.message">
    <TextArea ref="input" :data="contactFieldsData.message" @onUpdatedErrorStyle="updatedErrorStyle"/>
  </MessageBlock>
</template>



<script>
import { contactFieldsData } from '@/configs/data'
import ExtensionMessageBlock from '@/components/extensions/message/ExtensionMessageBlock.vue'
import MessageBlock from '@/components/base/message/MessageBlock.vue'
import TextArea from '@/components/base/inputs/TextArea.vue'

export default {
  extends: ExtensionMessageBlock,
  components: {
    MessageBlock,
    TextArea
  },
  setup() {
    return {
      ...ExtensionMessageBlock.setup(),
      contactFieldsData
    }
  }
}
</script>