<template>
  <div class="block">
    <div class="image">
      <img :src="require(`@/assets/images/portfolio/${imageName}`)"/>
    </div>
    <div class="information">
      <div class="block-title">
        <div class="icon">
          <slot></slot>
        </div>
        <div class="titles">
          <TitleBlockKeyValue :keyTitle="work" :valueTitle="date"/>
        </div>
      </div>
      <Text>{{description}}</Text>
    </div>
  </div>
</template>



<script>
import TitleBlockKeyValue from '@/components/common/text/TitleBlockKeyValue.vue'
import Text from '@/components/base/text/Text.vue'

export default {
  props: {
    work: {
      type: String
    },
    date: {
      type: String
    },
    description: {
      type: String
    },
    imageName: {
      type: String
    }
  },
  components: {
    TitleBlockKeyValue,
    Text
  }
}
</script>



<style lang="scss" scoped>
.block {
  display: flex;
}

.image {
  min-width: 190px;
  margin-right: 20px;
}

.image img {
  width: 100%;
}

.information {
  display: flex;
  flex-direction: column;
}

.block-title {
  display: flex;
  align-items: end;
  margin-bottom: 18px;
}

.icon {
  margin-right: 25px;
}

.titles {
  padding-bottom: 8px;
}

.text {
  margin-top: 12px;
}


@media screen and (max-width: $width-mobile) {
  .block {
    width: 100%;
  }

  .image {
    min-width: 94px;
  }
}
</style>