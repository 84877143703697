<template>
  <main>
    <slot></slot>
  </main>
</template>



<style lang="scss" scoped>
main {
  padding-top: 160px !important;
}


@media screen and (max-width: $width-mobile) {
  main {
    padding-top: 140px !important;
  }
}
</style>